import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const isDesktop = useResponsive('up', 'lg');
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={
        isDesktop
          ? {
              width: 140,
              height: 40,
              display: 'inline-flex',
              ...sx,
            }
          : { width: 140, height: 30, display: 'inline-flex', ...sx }
      }
      {...other}
    >
      <img
        src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/acma/logo-blue-dark%403x.png"
        alt="logo"
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
