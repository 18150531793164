export function Step1Validation(
  company,
  notListedValue,
  names,
  type,
  complaintNature,
  complaintDetails,
  complaintDate,
  userZip
) {
  let error = '';

  // Check if name exists
  if (!company) {
    error = 'Company Name is required';
    return error;
  }

  if (company === 'Other/Not Listed' && !notListedValue) {
    error = 'Other company name is required';
    return error;
  }

  // Check if name exists
  if (!type) {
    error = 'Please select a complaint type';
    return error;
  }

  if (!complaintNature) {
    error = 'Please select the nature of your complaint';
    return error;
  }

  if (!complaintDate) {
    error = 'Please select a complaint date';
    return error;
  }

  if (!complaintDetails) {
    error = 'Please add complaint details';
    return error;
  }

  // Check if zip exists is in a valid format
  if (!userZip || userZip.length < 4) {
    error = 'Zip code is required and must be in the correct format';
    return error;
  }

  // If the form is valid, return an error object with an empty message
  error = '';
  return error;
}
