import { Alert, AlertTitle, Card, CardContent, Divider, Grid } from '@mui/material';
import { useState, createContext } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useResponsive from '../../hooks/useResponsive';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import { Step1Validation } from '../../helpers/validation/Step1Validation';
import { IACET_IMG } from '../../constants/images';
import { Step2Validation } from '../../helpers/validation/Step2Validation';
import { handleComplaint } from '../../helpers/actions/handleComplaint';

const steps = ['Tell us about your complaint', 'Tell us about yourself', 'Review and submit your complaint'];
export const CompanyContext = createContext();

export default function CompanyStepper(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [names, setNames] = useState('');
  const [email, setEmail] = useState('');
  const [userZip, setUserZip] = useState('');
  const [type, setType] = useState('');
  const [complaintNature, setComplaintNature] = useState('');
  const [company, setCompany] = useState(props.companyName || '');
  const [notListedValue, setNotListedValue] = useState(props.notListedValue || '');
  const [complaintDetails, setComplaintDetails] = useState('');
  const [userName, setUserName] = useState('');
  const [termsCheck, setTermsCheck] = useState(false);
  const [complaintDate, setComplaintDate] = useState(new Date());

  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();

  const handleNext = async () => {
    if (activeStep === 0) {
      const err = Step1Validation(
        company,
        notListedValue,
        names,
        type,
        complaintNature,
        complaintDetails,
        complaintDate,
        userZip
      );
      if (!err) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.error(err);
      }
    } else if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSumbit = () => {
    const finalCompanyValue = company.length >= 1 && company !== 'Other/Not Listed' ? company : notListedValue;
    const complainObj = {
      company: finalCompanyValue,
      names,
      type,
      complaintNature,
      complaintDetails,
      complaintDate,
      userZip,
      userName,
      email,
    };
    if (termsCheck) {
      const complainAdd = handleComplaint(complainObj, navigate);
    } else {
      toast.error('Please Agree to the Submission Terms');
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item md={9}>
          <Card>
            <CardContent sx={{ mt: 2, mb: 2 }}>
              <Stepper activeStep={activeStep} sx={{ mb: 5 }}>
                {steps.map((label) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      {isDesktop ? (
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      ) : (
                        <StepLabel {...labelProps}>{}</StepLabel>
                      )}
                    </Step>
                  );
                })}
              </Stepper>
              <CompanyContext.Provider
                value={{
                  notListedValue,
                  setNotListedValue,
                  company,
                  setCompany,
                  names,
                  setNames,
                  type,
                  setType,
                  complaintNature,
                  setComplaintNature,
                  complaintDetails,
                  setComplaintDetails,
                  complaintDate,
                  setComplaintDate,
                  userZip,
                  setUserZip,
                  userName,
                  setUserName,
                  email,
                  setEmail,
                  termsCheck,
                  setTermsCheck,
                }}
              >
                {activeStep === steps.length - 1 ? (
                  <>
                    <Step2 />
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Edit Complaint</Button>
                      <Button onClick={handleSumbit} className="ml-2" variant="contained" size="large">
                        Submit Complaint
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    {activeStep === 0 ? (
                      <>
                        <Step0 />
                      </>
                    ) : activeStep === 1 ? (
                      <>
                        <Step1 />
                      </>
                    ) : activeStep === 2 ? (
                      <>
                        <Step2 />
                      </>
                    ) : (
                      <></>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        variant="contained"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button variant="contained" onClick={handleNext} sx={{ mr: 1 }}>
                        {activeStep === steps.length - 1 ? <>Submit Complaint</> : <>Next</>}
                      </Button>
                    </Box>
                  </>
                )}
              </CompanyContext.Provider>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card>
            <CardContent>
              <div className="flex justify-center align-center">
                <img src={IACET_IMG} alt="Iacet Logo" width="80%" />
              </div>
              <div className="text-center mt-3">
                <Typography variant="subtitle">A Seal of Excellence and a Guarantee of Quality</Typography>
                {/* <Divider sx={{ mt: 3, mb: 3 }} /> */}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
