import { Box, Card, CardContent, Checkbox, Divider, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { CompanyContext } from './CompanyStepper';

export default function Step2() {
  const {
    notListedValue,
    termsCheck,
    setTermsCheck,
    company,
    names,
    userZip,
    type,
    complaintNature,
    complaintDate,
    userName,
    email,
  } = useContext(CompanyContext);

  const updateDateFormat = (complaintDate) => {
    const incomingDate = new Date(complaintDate);
    return incomingDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  return (
    <div>
      <div className="mt-2 mb-2">
        <Typography variant="h4">Review and submit your complaint</Typography>
      </div>

      <Card>
        <CardContent>
          <div className="mt-2 mb-2">
            <Typography variant="subtitle">Complaint Details</Typography>
          </div>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div className="flex align-center">
                <Typography>
                  <strong>Company Name :</strong>
                </Typography>
                <Typography className="ml-1">{company || 'Unknown'}</Typography>
              </div>
            </Grid>
            {notListedValue && notListedValue.length >= 1 ? (
              <>
                <Grid item md={6}>
                  <div className="flex align-center">
                    <Typography>
                      <strong>Other Company Name :</strong>
                    </Typography>
                    <Typography className="ml-1">{notListedValue || 'Unknown'}</Typography>
                  </div>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item md={6}>
              <div className="flex align-center">
                <Typography>
                  <strong>Name(s) : </strong>
                </Typography>
                <Typography className="ml-1">{names || 'Unknown'}</Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="flex align-center">
                <Typography>
                  <strong>Complaint Type : </strong>
                </Typography>
                <Typography className="ml-1"> {type || 'Unknown'}</Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="flex align-center">
                <Typography>
                  <strong>Nature of Complaint : </strong>
                </Typography>
                <Typography className="ml-1">{complaintNature || 'Unknown'}</Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="flex align-center">
                <Typography>
                  <strong>Date of Complaint : </strong>
                </Typography>
                <Typography className="ml-1">{String(updateDateFormat(complaintDate)) || 'Unknown'}</Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="flex align-center">
                <Typography>
                  <strong>Location of Complaint : </strong>
                </Typography>
                <Typography className="ml-1">{userZip || 'Unknown'}</Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <div className="mt-2 mb-2">
            <Typography variant="subtitle">User Details</Typography>
          </div>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div className="flex align-center">
                <Typography>
                  <strong>Display Name : </strong>
                </Typography>
                <Typography className="ml-1">{userName || 'Not Provided'}</Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="flex align-center">
                <Typography>
                  <strong>Email : </strong>
                </Typography>
                <Typography className="ml-1">{email || 'Not Provided'}</Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Box className="mt-1 flex align-center">
            <Checkbox
              checked={termsCheck}
              onChange={(event) => setTermsCheck(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>
              I have read and agree to the{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://medicalaffairsspecialist.org/policy/terms-and-conditions"
              >
                Terms & Conditions
              </a>
              <a target="_blank" rel="noreferrer" href="https://medicalaffairsspecialist.org/policy/privacy">
                {' '}
                | Privacy Policy
              </a>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}
