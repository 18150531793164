import { Container, Grid, Card, CardMedia, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material/styles';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faqs() {
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <title>Report Pharma FAQs</title>
        <meta
          name="description"
          content="The Pharma FAQs report is a comprehensive resource that provides answers to frequently asked questions about the pharmaceutical industry."
        />
        <meta
          name="og:image"
          content={
            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/complaintPortal/Hexagon+Compressed.png'
          }
        />
      </Helmet>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Card>
              <CardMedia
                component="img"
                height="250"
                image="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/acmaMarketplace/FAQBackground.png"
                alt="FAQs"
                loading="lazy"
              />

              <Box>
                <div className="text-center-on-image flex column">
                  <Typography variant="h1" className="text-center" style={{ color: 'white' }}>
                    FAQs
                  </Typography>
                  <Typography variant="h4" className="text-center" style={{ color: 'white' }}>
                    How can we help you?
                  </Typography>
                </div>
              </Box>
            </Card>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>1. Is the information I submit in a complaint secure?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes, the ACMA takes the security of your information very seriously. We understand that submitting a
                    complaint may involve sensitive information, and we take all necessary measures to protect your
                    privacy. To ensure the security of your information, the ACMA uses the latest encryption technology
                    to safeguard your data when submitting complaints. In addition to encryption, the ACMA also has
                    strict policies and procedures in place to protect your information once recieved.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>2. How long do you keep the information I submit on file?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The ACMA will keep all information on file for at least 15 years from the date of submission.{' '}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography>3. How do I file a complaint against a company?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Click on the <a href="/complaint">File a Complaint</a> tab located on the <a href="/">Home page</a>.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
                  <Typography>4. I want to leave a review on a company, is that the same as a complaint?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No, reviews are not considered to be the same as complaints. You should file a complaint when you
                    have been part of or witnessed an inappropriate interaction between a representative of a
                    pharmaceutical, biotechnology, medical device or diagnostic company with a healthcare provider or
                    their staff. All complaints made must be accurate and truthful to ensure the integrity of the
                    complaint portal and to avoid investing time into fake complaints. This will take time away from
                    real complaints that need attention.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography>
                    5. If ACMA works with pharmaceutical companies, how can it ensure fairness in handling complaints?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The ACMA’s value is based on our neutral position in the industry. While we provide accreditation
                    and certification of pharmaceutical industry professionals, this is a fee for service model. Unlike
                    other organizations, where pharmaceutical companies pay an active membership fee, ACMA does NOT have
                    this model. Therefore, the ACMA acts as a liaison between healthcare providers and pharamaceutical
                    companies to promote trustworthy interactions and resolve disputes. Ultimately, the purpose is to
                    ensure patient care is not compromised due to inappropriate or unethical behaviors from
                    pharmaceutical company representatives.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                  <Typography>
                    6. I have a complaint against a company that is not pharmaceutical, biotechnology, diagnostic or
                    medical device company. can I file a complaint using this portal?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No. The ReportPharma.Org portal is strictly for filing complaints on interactions you have had with
                    pharmaceutical, biotechnology, medical device, or diagnostic companies.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                  <Typography>
                    7. I currently work with a pharmaceutical company and have been made aware that several complaints
                    have been filed against our company. What should I do about it?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Please contact the ACMA at <a href="mailto:info@acmainfo.org">info@acmainfo.org</a> to discuss your
                    options in addressing these complaints and ways to improve compliance.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                  <Typography>8. What does the ACMA do with the information provided in complaints?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Although the ACMA does not have any policing power, the ACMA believes it has the responsibility to
                    report this information to the proper authorities such as government agencies or the companies
                    involved in the complaint to ensure that prompt action is taken to avoid these circumstances in the
                    future. Most importantly, ensuring that patient care is not compromised in anyway due to unethical
                    behaviors by pharmaceutical companies.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                  <Typography>
                    9. My healthcare institution gets visits from both pharmaceutical sales representatives and medical
                    science liaisons (MSLs). What is the difference between the two roles and can I report unethical
                    behavior by both MSLs and pharmaceutical sales representatives?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The difference between pharmaceutical sales representatives and MSLs is that pharmaceutical sales
                    representatives are visiting doctors to not only educate but sell them on the benefits of using
                    their particular product. They are incentivized based on their sales results. However, MSLs do NOT
                    have any sales goals per se and are there to educate only as well as provide more in depth
                    scientific and clinical information to healthcare providers. You can certainly report unethical
                    behavior that occurred by both MSLs and pharmaceutical sales representatives.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
                  <Typography>
                    10. What does the ACMA do besides handling complaints by healthcare providers and their staff
                    against pharmaceutical companies?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The Accreditation Council for Medical Affairs (ACMA) is the first accrediting body for the
                    pharmaceutical industry. The ACMA certifies and accredits pharmaceutical industry professionals such
                    as medical affairs/MSL professionals, pharmaceutical sales representatives, field reimbursement
                    specialists as well as provide certification in specific areas such as pharmaceutical industry
                    compliance. To learn more about what the ACMA does,{' '}
                    <a href="https://medicalaffairsspecialist.org/about">click here</a>.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          {/* <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>1. Is the information I submit in a complaint secure?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Yes. The ACMA uses the latest security features including two factor authentication and encryption
                    technology to ensure that all submissions are secure.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>2. How long do you keep the information I submit on file?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The ACMA will keep all information on file for at least 15 years from the date of submission.{' '}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography>3. How do I file a complaint against a company?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Click on the <a href="/complaint">File a Complaint</a> tab located on the <a href="/">Home page</a>.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
                  <Typography>4. I want to leave a review on a company, is that the same as a complaint?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No, reviews are not considered to be the same as complaints. You should file a complaint when you
                    have been part of or witnessed an inappropriate interaction between a representative of a
                    pharmaceutical, biotechnology, medical device or diagnostic company with a healthcare provider or
                    their staff. All complaints made must be accurate and truthful to ensure the integrity of the
                    complaint portal and to avoid investing time into fake complaints. This will take time away from
                    real complaints that need attention.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <Typography>4. I want to leave a review on a company, is that the same as a complaint?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No, reviews are not considered to be the same as complaints. You should file a complaint when you
                    have been part of or witnessed an inappropriate interaction between a representative of a
                    pharmaceutical, biotechnology, medical device or diagnostic company with a healthcare provider or
                    their staff. All complaints made must be accurate and truthful to ensure the integrity of the
                    complaint portal and to avoid investing time into fake complaints. This will take time away from
                    real complaints that need attention.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography>
                    5. If ACMA works with pharmaceutical companies, how can it ensure fairness in handling complaints?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The ACMA’s value is based on our neutral position in the industry. While we provide accreditation
                    and certification of pharmaceutical industry professionals, this is a fee for service model. Unlike
                    other organizations, where pharmaceutical companies pay an active membership fee, ACMA does NOT have
                    this model. Therefore, the ACMA acts as a liaison between healthcare providers and pharamaceutical
                    companies to promote trustworthy interactions and resolve disputes. Ultimately, the purpose is to
                    ensure patient care is not compromised due to inappropriate or unethical behaviors from
                    pharmaceutical company representatives.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid> */}

          {/* <Grid item md={6}>
            <Box>
              <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                  <Typography>
                    6. I have a complaint against a company that is not pharmaceutical, biotechnology, diagnostic or
                    medical device company. can I file a complaint using this portal?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    No. The ReportPharma.Org portal is strictly for filing complaints on interactions you have had with
                    pharmaceutical, biotechnology, medical device, or diagnostic companies.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                  <Typography>
                    7. I currently work with a pharmaceutical company and have been made aware that several complaints
                    have been filed against our company. What should I do about it?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Please contact the ACMA at <a href="mailto:info@acmainfo.org">info@acmainfo.org</a> to discuss your
                    options in addressing these complaints and ways to improve compliance.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                  <Typography>8. What does the ACMA do with the information provided in complaints?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Although the ACMA does not have any policing power, the ACMA believes it has the responsibility to
                    report this information to the proper authorities such as government agencies or the companies
                    involved in the complaint to ensure that prompt action is taken to avoid these circumstances in the
                    future. Most importantly, ensuring that patient care is not compromised in anyway due to unethical
                    behaviors by pharmaceutical companies.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
                  <Typography>
                    10. What does the ACMA do besides handling complaints by healthcare providers and their staff
                    against pharmaceutical companies?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The Accreditation Council for Medical Affairs (ACMA) is the first accrediting body for the
                    pharmaceutical industry. The ACMA certifies and accredits pharmaceutical industry professionals such
                    as medical affairs/MSL professionals, pharmaceutical sales representatives, field reimbursement
                    specialists as well as provide certification in specific areas such as pharmaceutical industry
                    compliance. To learn more about what the ACMA does,{' '}
                    <a href="https://medicalaffairsspecialist.org/about">click here</a>.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
