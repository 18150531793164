import axios from 'axios';
import { useState, useEffect } from 'react';
import { GET_BLOGS_API } from '../constants/api';

export default function useGetBlogs() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      console.log('getting to the call');

      setLoading(true);
      axios
        .get(GET_BLOGS_API)
        .then((response) => {
          setData(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
      setLoading(false);
    }
    fetchData();
  }, []);

  return { data, error, loading };
}
