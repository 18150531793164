import { useEffect, useState } from 'react';
import { client } from '../../helpers/contentful/client';

// Used to get all entries with a specific content_type_name
//---------------------------------------------------------
function useContentTypesNames(contentTypeName) {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      await client
        .getEntries({ content_type: contentTypeName })
        .then((response) => {
          setEntries(response.items);
          setLoading(false);
        })
        .catch(setLoading(false));
    }
    fetchData();
  }, [contentTypeName]);

  return { entries, loading };
}

export default useContentTypesNames;
