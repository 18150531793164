import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import RateReviewIcon from '@mui/icons-material/RateReview';
import TimelineDot from '@mui/lab/TimelineDot';
import StarIcon from '@mui/icons-material/Star';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import PolicyIcon from '@mui/icons-material/Policy';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function TimelineSteps() {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <Typography variant="h3" className="text-center">
          File a Complaint in 3 Easy Steps
        </Typography>
      </div>
      <Timeline position="alternate" sx={{ mt: 6 }}>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <TextSnippetIcon color="white" />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              1. Provide Complaint Details
            </Typography>
            <Typography>
              Easily file a complaint through our portal by providing detailed information about your complaint
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <StarIcon color="white" />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              2. Review
            </Typography>
            <Typography>After providing your complaint ACMA, will review to determine further actions</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <PolicyIcon color="white" />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
              3. Investigate
            </Typography>
            <Typography>
              If ACMA finds your complaint valid, we will investigate the issue and take appropriate measures{' '}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <div className="flex justify-center align-center mt-8">
        <Button variant="contained" onClick={() => navigate('/complaint')}>
          Take Action Now
        </Button>
      </div>
    </>
  );
}
