import { Box, Card, CardContent, CardHeader, Chip, CircularProgress, Container, Grid, Typography } from '@mui/material';
import useGetBlogs from '../../hooks/useGetBlogs';
import { htmlEntityDecode } from '../../helpers/htmlEntityDecoder';
import useResponsive from '../../hooks/useResponsive';

export default function Blogs() {
  const { data, error, loading } = useGetBlogs();
  const isDesktop = useResponsive('up', 'lg');

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      {data && data.length === 3 ? (
        <Box sx={{ mt: 10, mb: 20, p: isDesktop ? 8 : 2, backgroundColor: '#76c3a12e', borderRadius: '16px' }}>
          <Container>
            <Box>
              <div>
                <Typography variant="h3">Latest News</Typography>
                <Typography>Breaking Down Pharmaceutical News</Typography>
              </div>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {data && data.length === 3 ? (
                  data
                    .map((item) => (
                      <Grid key={item.id} item md={4} container>
                        <Card
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <CardHeader
                            title={
                              <div className="flex justify-between align-center">
                                <Typography variant="h6">{item.publish_date}</Typography>
                                <Chip sx={{ backgroundColor: '#76c3a1' }} label="Featured" />
                              </div>
                            }
                          />
                          <CardContent>
                            <Typography>
                              {item.title && item.title.length > 105 ? (
                                <>{htmlEntityDecode(item.title).substring(0, 105)} ...</>
                              ) : (
                                htmlEntityDecode(item.title)
                              )}
                            </Typography>
                            <div className="mt-3">
                              <a href={item.link} rel="noopener noreferrer" target="_blank" className="customButton">
                                Learn More
                              </a>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                    .reverse()
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
          </Container>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
