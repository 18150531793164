// component
import SearchIcon from '@mui/icons-material/Search';
import { ACMA_ABOUT_US, FAQs, ACMA_CONTACT_US } from '../../../constants/urls';
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'home',
    path: '/',
    icon: <SearchIcon />,
    external: false,
  },

  {
    title: 'File a Complaint',
    path: '/complaint',
    icon: <SearchIcon />,
    external: false,
  },

  {
    title: 'View Complaints',
    path: '/view-complaints',
    icon: <SearchIcon />,
    external: false,
  },

  {
    title: 'About ACMA',
    path: ACMA_ABOUT_US,
    icon: <SearchIcon />,
    external: true,
  },

  {
    title: 'Blogs',
    path: '/blogs',
    icon: <SearchIcon />,
    external: false,
  },
  {
    title: 'FAQs',
    path: FAQs,
    icon: <SearchIcon />,
    external: true,
  },
  {
    title: 'Contact Us',
    path: ACMA_CONTACT_US,
    icon: <SearchIcon />,
    external: true,
  },
];

export default navConfig;
