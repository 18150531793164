import axios from 'axios';
import { toast } from 'react-hot-toast';
import { API_URL } from '../../constants/api';

export async function handleComplaint(complainObj, navigate) {
  try {
    axios
      .post(
        API_URL,
        {
          complainObj,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response && response.data && response.data.status === 'ok') {
          toast.success('Successfully Added Complaint');
          navigate('/success');
        } else {
          toast.error('Error Adding Complaint - Please Try Again');
        }
      })
      .catch((error) => {
        console.log('Server was unable add complaint', error);
      });
  } catch (err) {
    console.log(err);
  }
}
