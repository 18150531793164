import PropTypes from 'prop-types';
import * as React from 'react';

// @mui
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Container, IconButton, Button, Typography } from '@mui/material';
// utils
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '../../../components/logo/Logo';

import { bgBlur } from '../../../utils/cssStyles';
// components
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import useResponsive from '../../../hooks/useResponsive';
import { ACMA_ABOUT_US, ACMA_CONTACT_US } from '../../../constants/urls';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------
Header.propTypes = {
  onOpenNav: PropTypes.func,
};

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Header(props) {
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();

  return (
    <ElevationScroll {...props}>
      <StyledRoot>
        <Container>
          <StyledToolbar style={{ padding: 0 }}>
            <Logo />

            <Box sx={{ flexGrow: 1 }} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
            >
              <IconButton
                onClick={props.onOpenNav}
                sx={{
                  mr: 1,
                  color: 'text.primary',
                  display: { lg: 'none' },
                }}
              >
                <MenuIcon />
              </IconButton>
              {isDesktop ? (
                <>
                  <Typography variant="subtitle" sx={{ color: 'black', pl: 2, pr: 2 }}>
                    <Link
                      style={{ textDecoration: 'none', color: 'black' }}
                      to={{ pathname: ACMA_ABOUT_US }}
                      target="_blank"
                    >
                      About
                    </Link>
                  </Typography>
                  <Typography variant="subtitle" sx={{ color: 'black', pl: 2, pr: 2 }}>
                    <Link
                      style={{ textDecoration: 'none', color: 'black' }}
                      to={{ pathname: ACMA_CONTACT_US }}
                      target="_blank"
                    >
                      Contact Us
                    </Link>
                  </Typography>
                  <Typography variant="subtitle" sx={{ color: 'black', pl: 2, pr: 2 }}>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to={'/blogs'}>
                      Blogs
                    </Link>
                  </Typography>
                  <Typography variant="subtitle" sx={{ color: 'black', pl: 2, pr: 2 }}>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to={'/faqs'}>
                      FAQs
                    </Link>
                  </Typography>
                  <Button
                    style={{ marginLeft: '22px' }}
                    variant="contained"
                    size="large"
                    onClick={() => navigate('/complaint')}
                  >
                    File a Complaint
                  </Button>
                </>
              ) : (
                ''
              )}
            </Stack>
          </StyledToolbar>
        </Container>
      </StyledRoot>
    </ElevationScroll>
  );
}
