import { MobileDatePicker } from '@mui/lab';
import DatePicker from 'react-datepicker';

import {
  Alert,
  AlertTitle,
  Autocomplete,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { CompanyContext } from './CompanyStepper';
import 'react-datepicker/dist/react-datepicker.css';
import { companies } from '../../_mock/companies';

export default function Step0() {
  const {
    notListedValue,
    setNotListedValue,
    company,
    setCompany,
    names,
    setNames,
    userZip,
    setUserZip,
    type,
    setType,
    complaintNature,
    setComplaintNature,
    complaintDate,
    setComplaintDate,
    complaintDetails,
    setComplaintDetails,
  } = useContext(CompanyContext);

  return (
    <>
      <div className="mt-2 mb-2">
        <Typography variant="h4">Tell us about your complaint</Typography>
      </div>

      <form className="mt-5">
        <Grid spacing={3} container>
          <Grid md={12} item width="100%">
            <Autocomplete
              required
              freeSolo
              fullWidth
              disablePortal
              value={company}
              onChange={(event, newValue) => {
                setCompany(newValue);
              }}
              id="combo-box-demo"
              options={companies.map((option) => option.Companies)}
              renderInput={(params) => <TextField {...params} label="Company Name (Required) *" />}
              helperText="Company Name (Required) *"
            />
            <p className="helper-text-custom no-margin-or-padding">
              Report unlisted pharmaceutical companies by{' '}
              <a target="_blank" rel="noreferrer" href="https://medicalaffairsspecialist.org/about/contact-us">
                contacting us
              </a>
            </p>
          </Grid>
          {(notListedValue && notListedValue.length >= 1) || company === 'Other/Not Listed' ? (
            <>
              <Grid md={12} item width="100%">
                <TextField
                  value={notListedValue}
                  onChange={(e) => setNotListedValue(e.target.value)}
                  required={company === 'Other/Not Listed' || false}
                  fullWidth
                  label="Specify Other Company"
                  variant="outlined"
                  helperText="Specify a company that's not listed in the dropdown above"
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid md={12} item width="100%">
            <TextField
              value={names}
              onChange={(e) => setNames(e.target.value)}
              fullWidth
              label="Names (Optional)"
              variant="outlined"
              helperText="List all individuals / organizations associated with this complaint. Please seperate by commas."
            />
          </Grid>
          <Grid item md={12} className="mt-2 mb-3">
            <Divider variant="middle" />
          </Grid>

          <Grid md={6} item width="100%">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Complaint Type (Required) *</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Complaint Type (Required) *"
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={'Service Issue'}>Service Issue</MenuItem>
                <MenuItem value={'Product Issue'}>Product Issue</MenuItem>
                <MenuItem value={'Professional Standards Issue'}>Professional Standards Issue</MenuItem>
                <MenuItem value={'Legal Issue'}>Legal Issue</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid md={6} item width="100%">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Nature of Complaint (Required) *</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={complaintNature}
                label="Nature of Complaint (Required) *"
                onChange={(e) => setComplaintNature(e.target.value)}
              >
                <MenuItem value={'Off label drug promotion'}>Off label drug promotion</MenuItem>
                <MenuItem value={'Not Presenting clinical information in an objective way'}>
                  Not Presenting clinical information in an objective way
                </MenuItem>
                <MenuItem value={'Presenting clinical data incorrectly'}>Presenting clinical data incorrectly</MenuItem>
                <MenuItem value={'Violation of anti-kickback statute'}>Violation of anti-kickback statute</MenuItem>
                <MenuItem value={'Inappropriate sampling of product'}>Inappropriate sampling of product</MenuItem>
                <MenuItem value={'Inappropriate conduct while in office'}>
                  Inappropriate conduct while in office
                </MenuItem>
                <MenuItem value={'Inappropriate interaction with patients'}>
                  Inappropriate interaction with patients
                </MenuItem>
                <MenuItem value={'Not presenting safety & adverse event information'}>
                  Not presenting safety & adverse event information
                </MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid md={12} item width="100%">
            <TextField
              required
              sx={{ width: '100%' }}
              label="Tell Us About Your Complaint in Details (Required)"
              multiline
              rows={5}
              variant="outlined"
              value={complaintDetails}
              onChange={(e) => setComplaintDetails(e.target.value)}
            />
          </Grid>

          <Grid md={6} item width="100%">
            <DatePicker
              required
              style={{ width: '100%' }}
              placeholder={`Select Start Time`}
              selected={complaintDate}
              dateFormat="MM-dd-yyyy"
              onChange={(date) => setComplaintDate(date)}
            />
            <p className="helper-text-custom no-margin-or-padding">When did this incident occur?</p>
          </Grid>

          <Grid md={6} item width="100%">
            <TextField
              required
              value={userZip}
              onChange={(e) => setUserZip(e.target.value)}
              fullWidth
              label="Location of Complaint (zip code) (Required)"
              variant="outlined"
              helperText="Where did this incident occur?"
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
