import { Link } from 'react-router-dom';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Typography } from '@mui/material';
import WhiteLogo from './logo/WhiteLogo';

export default function Footer() {
  return (
    <div className="mt-5">
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-row">
            <div className="footer-col flex justify-center align-center">
              <WhiteLogo />
            </div>
            <div className="footer-col">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/complaint">File a Complaint</Link>
                </li>
                <li>
                  <Link to="/view-complaints">View Complaints</Link>
                </li>
                <li>
                  <Link to="/blogs">Blog</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Get Help</h4>
              <ul>
                <li>
                  <a href="https://medicalaffairsspecialist.org/about">About ACMA</a>
                </li>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/about/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/policy/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/policy/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </div>

            <div className="footer-col">
              <h4>Follow us</h4>
              <div>
                <h6 style={{ color: 'white' }}>Accreditation Council For Medical Affairs (ACMA)</h6>
                <div>
                  <a href="https://www.facebook.com/medicalaffairsspecialist/">
                    <FacebookRoundedIcon className="footer-social-icons" />
                  </a>
                  <a href="https://twitter.com/The_ACMA?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                    <TwitterIcon className="footer-social-icons ml-2" />
                  </a>
                  <a href="https://www.instagram.com/acmapharma/">
                    <InstagramIcon className="footer-social-icons ml-2" />
                  </a>
                  <a href="https://www.linkedin.com/company/accreditation-council-for-medical-affairs-acma-/mycompany/verification/">
                    <LinkedInIcon className="footer-social-icons ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Typography
              varaint="h7"
              style={{ color: '#bbbbbb', fontSize: '16px', textAlign: 'center' }}
              className="mt-8 p-7"
            >
              Copyright © 2023 Accreditation Council for Medical Affairs. All Rights Reserved.
            </Typography>
          </div>
        </div>
      </footer>
    </div>
  );
}
