import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import BLOG_CONTENT from '../../_mock/blog';
import useResponsive from '../../hooks/useResponsive';
import useContentTypesNames from '../../hooks/contentful/useGetContentByName';

export default function Blogs() {
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');
  const { entries, loading } = useContentTypesNames('blog');
  console.log(entries);
  const featuredBlog = entries.find((item) => item.fields.featuredBlog === true);
  const newEntries = entries
    .filter((item) => item !== featuredBlog) // filter out the featured blog
    .sort((a, b) => new Date(b.fields.publishDate) - new Date(a.fields.publishDate));
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      {/* Featured Post */}
      <Helmet>
        <title>ACMA Blogs</title>
        <meta
          name="description"
          content="Stay up-to-date on the latest developments in healthcare and pharma with our informative blog. From breaking news to expert analysis, we cover it all. Join the conversation and expand your knowledge today"
        />
        <meta
          name="og:image"
          content={
            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/complaintPortal/Hexagon+Compressed.png'
          }
        />
      </Helmet>
      {featuredBlog ? (
        <>
          <Card sx={{ mt: 5 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <img
                    src={featuredBlog.fields.blogImage.fields.file.url || ''}
                    alt={featuredBlog.fields.blogTitle}
                    loading="lazy"
                  />
                </Grid>
                <Grid item md={6}>
                  <Chip label="Featured Post" />
                  <Typography variant="h3" className="mt-2">
                    <Link to={`/blog${featuredBlog.fields.slug}`} className="blog-header">
                      {featuredBlog.fields.blogTitle}
                    </Link>
                  </Typography>
                  <Typography className="mt-2">{featuredBlog.fields.metaDescription}...</Typography>
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={() => navigate(`/blog${featuredBlog.fields.slug}`)}
                  >
                    Learn More
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      ) : (
        <></>
      )}

      <Box sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h3" className={!isDesktop ? 'text-center' : ''}>
          Recent Posts
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {newEntries &&
          newEntries.map((item) => (
            <Grid item md={4} container key={item.name} sx={{ mt: 2 }}>
              <Card>
                <CardMedia
                  sx={{ height: 140 }}
                  image={item.fields.blogImage.fields.file.url}
                  title={item.fields.blogTitle}
                />
                <CardContent>
                  <Typography sx={{ fontSize: '12px' }}>
                    {item.fields.author} | {item.fields.readTime} Minutes
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div">
                    <Link to={`/blog${item.fields.slug}`} className="blog-header">
                      {item.fields.blogTitle}
                    </Link>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.fields.metaDescription}...
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    sx={{ mb: 2, m: 3 }}
                    fullWidth
                    variant="contained"
                    onClick={() => navigate(`/blog${item.fields.slug}`)}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
