export const companies = [
  {
    Companies: '3M',
    Region: 'USA',
  },
  {
    Companies: '3NT',
    Region: 'Israel',
  },
  {
    Companies: '89bio',
    Region: 'USA',
  },
  {
    Companies: 'A.P. Pharma, Inc.-now Heron Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Aadi Bioscience',
    Region: 'USA',
  },
  {
    Companies: 'Abbott ',
    Region: 'USA',
  },
  {
    Companies: 'Abbott India',
    Region: 'India',
  },
  {
    Companies: 'Abbott Middle East',
    Region: 'Middle East',
  },
  {
    Companies: 'AbbVie',
    Region: 'USA',
  },
  {
    Companies: 'AbCellera',
    Region: 'Canada',
  },
  {
    Companies: 'Abeona Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Abiomed',
    Region: 'USA',
  },
  {
    Companies: 'Able Medical Devices',
    Region: 'USA',
  },
  {
    Companies: 'Abpro',
    Region: 'USA',
  },
  {
    Companies: 'AC Immune SA',
    Region: 'Switzerland',
  },
  {
    Companies: 'Acacia Pharma Gr',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Acadia Healthcare',
    Region: 'USA',
  },
  {
    Companies: 'Acadia Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Acasti Pharma',
    Region: 'Canada',
  },
  {
    Companies: 'Acceleron Pharma (Acquired by Merck)',
    Region: 'USA',
  },
  {
    Companies: 'Accuray Incorporated',
    Region: 'USA',
  },
  {
    Companies: 'Accuray International S\u00c3\u00a0rl',
    Region: 'United Kingdom',
  },
  {
    Companies: 'AcelRx',
    Region: 'USA',
  },
  {
    Companies: 'Acino Holding',
    Region: 'Switzerland',
  },
  {
    Companies: 'Aclaris Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Acorda Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Actelion',
    Region: 'Switzerland',
  },
  {
    Companies: 'ActiveHealth Management',
    Region: 'USA',
  },
  {
    Companies: 'Acuitive Technologies',
    Region: 'USA',
  },
  {
    Companies: 'Adamas Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Adamis Pharmaceuticals Corporation',
    Region: 'USA',
  },
  {
    Companies: 'ADAPTIMMUNE THERAPEUTICS PLC',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Adaptive Biotechnologies',
    Region: 'USA',
  },
  {
    Companies: 'Adaptive Phage Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Adare Pharmaceuticals (now Adare Pharmaceuticals)',
    Region: 'USA',
  },
  {
    Companies: 'ADC Therapeutics',
    Region: 'Switzerland',
  },
  {
    Companies: 'Adherex Technologies Inc',
    Region: 'USA',
  },
  {
    Companies: 'Adial Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'ADMA Biologics',
    Region: 'USA',
  },
  {
    Companies: 'Aduro Biotech ',
    Region: 'USA',
  },
  {
    Companies: 'Advaxis',
    Region: 'USA',
  },
  {
    Companies: 'Adverum Biotechnologies ',
    Region: 'USA',
  },
  {
    Companies: 'Aeglea Biotherapeutics ',
    Region: 'USA',
  },
  {
    Companies: 'Aerie Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Aerogen',
    Region: 'Ireland',
  },
  {
    Companies: 'AEterna Zentaris',
    Region: 'USA',
  },
  {
    Companies: 'Aethlon Medical',
    Region: 'USA',
  },
  {
    Companies: 'Affimed',
    Region: 'Germany',
  },
  {
    Companies: 'Agenus ',
    Region: 'USA',
  },
  {
    Companies: 'Agile Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Agios Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Aileron Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Aimmune Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'AIS Healthcare',
    Region: 'USA',
  },
  {
    Companies: 'Akari Therapeutics',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Akcea Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Akebia',
    Region: 'USA',
  },
  {
    Companies: 'Akero Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Akorn ',
    Region: 'USA',
  },
  {
    Companies: 'Akouos',
    Region: 'USA',
  },
  {
    Companies: 'Albireo',
    Region: 'USA',
  },
  {
    Companies: 'Alcon',
    Region: 'Switzerland',
  },
  {
    Companies: 'Alcresta Therapeutics, Inc',
    Region: 'USA',
  },
  {
    Companies: 'Aldeyra Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Alector',
    Region: 'USA',
  },
  {
    Companies: 'Alembic Pharmaceuticals',
    Region: 'India',
  },
  {
    Companies: 'Alexion Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Alfasigma',
    Region: 'Italy',
  },
  {
    Companies: 'Alimera Sciences',
    Region: 'USA',
  },
  {
    Companies: 'Alkem Laboratories',
    Region: 'India',
  },
  {
    Companies: 'Alkermes',
    Region: 'Ireland',
  },
  {
    Companies: 'All Care',
    Region: 'USA',
  },
  {
    Companies: 'Allakos',
    Region: 'USA',
  },
  {
    Companies: 'Allena',
    Region: 'USA',
  },
  {
    Companies: 'Allogene Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Almirall',
    Region: 'Spain',
  },
  {
    Companies: 'Alnylam Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Alphamab Oncology',
    Region: 'China',
  },
  {
    Companies: 'Alpine Immune Sciences',
    Region: 'USA',
  },
  {
    Companies: 'Altimmune',
    Region: 'USA',
  },
  {
    Companies: 'Alvogen',
    Region: 'USA',
  },
  {
    Companies: 'Alzheon',
    Region: 'USA',
  },
  {
    Companies: 'AMAG Pharmaceuticals, Inc. (now Covis Pharma)',
    Region: 'USA',
  },
  {
    Companies: 'Amarin',
    Region: 'Ireland',
  },
  {
    Companies: 'Ambit Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Ambrx',
    Region: 'USA',
  },
  {
    Companies: 'AmerisourceBergen',
    Region: 'USA',
  },
  {
    Companies: 'Amgen',
    Region: 'USA',
  },
  {
    Companies: 'Amicus',
    Region: 'USA',
  },
  {
    Companies: 'Amneal Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Amphastar Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Amplity',
    Region: 'USA',
  },
  {
    Companies: 'AnaptysBio',
    Region: 'USA',
  },
  {
    Companies: 'Anavex Life Sciences',
    Region: 'USA',
  },
  {
    Companies: 'Anchiano Therapeutics-now Chemomab Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Angelini',
    Region: 'Italy',
  },
  {
    Companies: 'ANI Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Anixa Biosciences ',
    Region: 'USA',
  },
  {
    Companies: 'Antares Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Antengene',
    Region: 'China',
  },
  {
    Companies: 'Antibe Therapeutics',
    Region: 'Canada',
  },
  {
    Companies: 'Apellis Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Apollo Endosurgery',
    Region: 'USA',
  },
  {
    Companies: 'Apotex',
    Region: 'Canada',
  },
  {
    Companies: 'Applied Genetic Technologies Corporation (AGTC)',
    Region: 'USA',
  },
  {
    Companies: 'Applied Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Aprea ',
    Region: 'USA',
  },
  {
    Companies: 'Apricus Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Aptevo Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Aptinyx',
    Region: 'USA',
  },
  {
    Companies: 'Aptose Biosciences',
    Region: 'Canada',
  },
  {
    Companies: 'Aquestive Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Aravive ',
    Region: 'USA',
  },
  {
    Companies: 'Arbor Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Arbutus Biopharma',
    Region: 'USA',
  },
  {
    Companies: 'ARCA biopharma, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Arcellx',
    Region: 'USA',
  },
  {
    Companies: 'Arcturus Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Ardelyx',
    Region: 'USA',
  },
  {
    Companies: 'Arena Pharmaceuticals (part of Pfizer)',
    Region: 'USA',
  },
  {
    Companies: 'Argenx',
    Region: 'Netherlands',
  },
  {
    Companies: 'Aridis ',
    Region: 'USA',
  },
  {
    Companies: 'ArQule, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Array Biopharma',
    Region: 'USA',
  },
  {
    Companies: 'Arrowhead Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Aruvant',
    Region: 'USA',
  },
  {
    Companies: 'Arvelle Therapeutics',
    Region: 'Switzerland',
  },
  {
    Companies: 'Arvinas',
    Region: 'USA',
  },
  {
    Companies: 'Ascendis Pharma',
    Region: 'Denmark',
  },
  {
    Companies: 'Asembia',
    Region: 'USA',
  },
  {
    Companies: 'Ashfield-part of Inzio',
    Region: 'USA',
  },
  {
    Companies: 'ASLAN Pharma',
    Region: 'Singapore',
  },
  {
    Companies: 'Aspen Pharmacare',
    Region: 'South Africa',
  },
  {
    Companies: 'Assembly Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Assertio Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Astellas Gene Therapies-Formerly Audentes Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Astellas Pharma',
    Region: 'Japan',
  },
  {
    Companies: 'Astrazeneca',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Atara Biotherapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Athenex',
    Region: 'USA',
  },
  {
    Companies: 'athersys',
    Region: 'USA',
  },
  {
    Companies: 'Atlantic Health System',
    Region: 'USA',
  },
  {
    Companies: 'Atossa Genetics',
    Region: 'USA',
  },
  {
    Companies: 'Atreca',
    Region: 'USA',
  },
  {
    Companies: 'aTyr Pharma, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Aurinia Pharmaceuticals',
    Region: 'Canada ',
  },
  {
    Companies: 'Auris Medical Holding',
    Region: 'Switzerland',
  },
  {
    Companies: 'Aurobindo Pharma',
    Region: '',
  },
  {
    Companies: 'Autobahn Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Autolus Therapeutics',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Avadel Pharmaceuticals',
    Region: 'Ireland',
  },
  {
    Companies: 'Avalo Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Avanir Pharmaceuticals Inc',
    Region: 'USA',
  },
  {
    Companies: 'Avedro-part of Glaukos',
    Region: 'USA',
  },
  {
    Companies: 'Avenue therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'AVEO Oncology',
    Region: 'USA',
  },
  {
    Companies: 'Avexis-now Novartis Gene Therapies-Annie',
    Region: 'USA',
  },
  {
    Companies: 'Avidity Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Avita Medical Inc. ',
    Region: 'United Kingdom',
  },
  {
    Companies: 'AVM Biotechnology',
    Region: 'USA',
  },
  {
    Companies: 'Avrobio',
    Region: 'USA',
  },
  {
    Companies: 'Axcella Health',
    Region: 'USA',
  },
  {
    Companies: 'Axogen ',
    Region: 'USA',
  },
  {
    Companies: 'Axonics',
    Region: 'USA',
  },
  {
    Companies: 'Axsome Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Aytu BioPharma -formerly Aytu Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'B. Braun',
    Region: 'Germany',
  },
  {
    Companies: 'Baudax Bio',
    Region: 'USA',
  },
  {
    Companies: 'Bausch Health',
    Region: 'Canada',
  },
  {
    Companies: 'Baxter International',
    Region: 'USA',
  },
  {
    Companies: 'Bayer',
    Region: 'Germany',
  },
  {
    Companies: 'Bayer - BCMAS',
    Region: 'USA',
  },
  {
    Companies: 'Bayer-PACS',
    Region: 'USA',
  },
  {
    Companies: 'BB Biotech',
    Region: 'Switzerland',
  },
  {
    Companies: 'Beam Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Becton Dickinson (BD)',
    Region: 'USA',
  },
  {
    Companies: 'BeiGene, Ltd',
    Region: 'China',
  },
  {
    Companies: 'Bellerophon Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Bellicum Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Benitec Biopharma',
    Region: 'Australia',
  },
  {
    Companies: 'Berlin Chemie',
    Region: '',
  },
  {
    Companies: 'BeyondSpring',
    Region: 'USA',
  },
  {
    Companies: 'Bial',
    Region: 'Portugal',
  },
  {
    Companies: 'Bicycle Therapeutics',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Big Health',
    Region: 'USA',
  },
  {
    Companies: 'Bio-path holdings',
    Region: 'USA',
  },
  {
    Companies: 'Bio-Rad Laboratories',
    Region: 'USA',
  },
  {
    Companies: 'Bio-Techne',
    Region: 'USA',
  },
  {
    Companies: 'BioCardia',
    Region: 'USA',
  },
  {
    Companies: 'Biocodex S.A.',
    Region: 'France',
  },
  {
    Companies: 'Biocon',
    Region: 'India',
  },
  {
    Companies: 'BioCryst Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'BioDelivery Sciences International, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Biofrontera',
    Region: 'USA',
  },
  {
    Companies: 'Biogen',
    Region: 'USA',
  },
  {
    Companies: 'BioHaven Pharma',
    Region: 'USA',
  },
  {
    Companies: 'BioLineRx',
    Region: 'Israel',
  },
  {
    Companies: 'Biologic Coordinators of Dermatology (BCoD)',
    Region: 'USA',
  },
  {
    Companies: 'BioMarin Pharmaceutical',
    Region: 'USA',
  },
  {
    Companies: 'bioM\u00c3\u00a9rieux',
    Region: 'France',
  },
  {
    Companies: 'BiondVax',
    Region: 'Israel',
  },
  {
    Companies: 'BioNTech',
    Region: 'Germany',
  },
  {
    Companies: 'Biosensors International Group, Ltd',
    Region: 'Singapore',
  },
  {
    Companies: 'BioSig Technologies',
    Region: 'USA',
  },
  {
    Companies: 'Bioverativ-acquired by Sanofi',
    Region: 'USA',
  },
  {
    Companies: 'BioXcel',
    Region: 'USA',
  },
  {
    Companies: 'Blade Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Bluebird Bio',
    Region: 'USA',
  },
  {
    Companies: 'Blueprint Medicines',
    Region: 'USA',
  },
  {
    Companies: 'BlueRock Therapeutics ',
    Region: 'USA',
  },
  {
    Companies: 'BluPax Pharma Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Boehringer Ingelheim',
    Region: 'Germany',
  },
  {
    Companies: 'Bold Therapeutics ',
    Region: 'Canada',
  },
  {
    Companies: 'Boston Scientific',
    Region: 'USA',
  },
  {
    Companies: 'Bracco S.p.A.',
    Region: 'Italy',
  },
  {
    Companies: 'Braeburn',
    Region: 'USA',
  },
  {
    Companies: 'Brain Scientific',
    Region: 'USA',
  },
  {
    Companies: 'BrainLab',
    Region: 'Germany',
  },
  {
    Companies: 'Brainstorm Cell Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'BrainsWay Ltd. ',
    Region: 'USA',
  },
  {
    Companies: 'BridgeBio Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Bristol Myers Squibb',
    Region: 'USA',
  },
  {
    Companies: 'Cadila Healthcare',
    Region: 'India',
  },
  {
    Companies: 'Caladrius Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Calithera Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Camarus',
    Region: 'Sweden',
  },
  {
    Companies: 'Canon',
    Region: 'Japan',
  },
  {
    Companies: 'Capricor Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Cara Therapeutics ',
    Region: 'USA',
  },
  {
    Companies: 'Cardinal Health',
    Region: 'USA',
  },
  {
    Companies: 'CardioNXT',
    Region: 'USA',
  },
  {
    Companies: 'CareMetx, LLC',
    Region: 'USA',
  },
  {
    Companies: 'Carma Laboratories',
    Region: 'USA',
  },
  {
    Companies: 'Carmot Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Carrick Therapeutics',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Carrick Therapeutics',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Castle Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Catabasis Pharmaceuticals-now Astria Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Catalent',
    Region: 'USA',
  },
  {
    Companies: 'Catalyst Pharmaceuticals ',
    Region: 'USA',
  },
  {
    Companies: 'Cedars-Sinai',
    Region: 'USA',
  },
  {
    Companies: 'Celgene',
    Region: 'USA',
  },
  {
    Companies: 'Celldex Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Cellectar Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Cellectis',
    Region: 'France',
  },
  {
    Companies: 'Celltrion',
    Region: 'S. Korea',
  },
  {
    Companies: 'Cellular Biomedicine Group, Inc.',
    Region: 'China',
  },
  {
    Companies: 'Celsion-now Imunon',
    Region: 'USA',
  },
  {
    Companies: 'Celularity ',
    Region: 'USA',
  },
  {
    Companies: 'Celyad Oncology',
    Region: 'Belgium',
  },
  {
    Companies: 'Centrexion Therapeutics Corporation',
    Region: 'USA',
  },
  {
    Companies: 'CG Oncology Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Champions Oncology Inc',
    Region: 'USA',
  },
  {
    Companies: 'Checkpoint Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Chembio Diagnostics',
    Region: 'USA',
  },
  {
    Companies: 'Chemence Medical',
    Region: 'USA',
  },
  {
    Companies: 'ChemoCentryx',
    Region: 'USA',
  },
  {
    Companies: 'Chemomab',
    Region: 'Israel',
  },
  {
    Companies: 'Chiasma',
    Region: 'USA',
  },
  {
    Companies: 'Chiesi',
    Region: 'Italy',
  },
  {
    Companies: 'Chimerix, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'China Biologic Products',
    Region: 'China',
  },
  {
    Companies: 'CHRISTUS Health',
    Region: 'USA',
  },
  {
    Companies: 'Chugai Pharmaceutical Co.',
    Region: 'Japan',
  },
  {
    Companies: 'Cidara Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Cigna',
    Region: 'USA',
  },
  {
    Companies: 'Cipla Limited ',
    Region: 'India',
  },
  {
    Companies: 'Citius Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Clearside Biomedical, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Cleveland BioLabs, Inc.-now Cytocom Inc',
    Region: 'USA',
  },
  {
    Companies: 'Clinigen Group',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Clover Bio-B',
    Region: 'China',
  },
  {
    Companies: 'Clovis Oncology, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'CNS Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Cocrystal Pharma, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'CohBar, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Coherus BioSciences, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Colibri Heart Valve',
    Region: 'USA',
  },
  {
    Companies: 'Collegium Pharmaceutical',
    Region: 'USA',
  },
  {
    Companies: 'Compugen Ltd.',
    Region: 'Israel',
  },
  {
    Companies: 'Conatus Pharmaceuticals Inc.-now Histogen',
    Region: 'USA',
  },
  {
    Companies: 'Concert Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Conformis',
    Region: 'USA',
  },
  {
    Companies: 'CONMED Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Constellation Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'ContraFect Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Cook Medical',
    Region: 'USA',
  },
  {
    Companies: 'CooperSurgical',
    Region: 'USA',
  },
  {
    Companies: 'Corbus Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Corcept Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Corindus Vascular Robotics',
    Region: 'USA',
  },
  {
    Companies: 'Corium',
    Region: 'USA',
  },
  {
    Companies: 'Cortexyme',
    Region: 'USA',
  },
  {
    Companies: 'Corvus Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Covis Pharma ',
    Region: 'Switzerland',
  },
  {
    Companies: 'Cradle Genomics',
    Region: 'USA',
  },
  {
    Companies: 'Crinetics Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'CRISPR Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'CRISPR Therapeutics',
    Region: 'Switzerland',
  },
  {
    Companies: 'CSBIO',
    Region: 'USA',
  },
  {
    Companies: 'CSL',
    Region: 'Australia',
  },
  {
    Companies: 'CSL Behring ',
    Region: 'USA',
  },
  {
    Companies: 'CTI BioPharma Corp-Annie has customized Training deal out ',
    Region: 'USA',
  },
  {
    Companies: 'Cue Biopharma, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Cumberland Pharmaceuticals Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Curevac',
    Region: 'Germany',
  },
  {
    Companies: 'Curis, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Currax Pharmaceuticals LLC',
    Region: 'USA',
  },
  {
    Companies: 'CVI Pharmaceuticals Ltd.',
    Region: 'China',
  },
  {
    Companies: 'CVS Health',
    Region: 'USA',
  },
  {
    Companies: 'Cyclacel Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'CymaBay Therapeutics Inc.',
    Region: 'USA',
  },
  {
    Companies: 'CytoDyn',
    Region: 'USA',
  },
  {
    Companies: 'Cytokinetics, Incorporated',
    Region: 'USA',
  },
  {
    Companies: 'CytomX Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'CytoSorbents',
    Region: 'USA',
  },
  {
    Companies: 'CytRx Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Daiichi Sankyo',
    Region: 'Japan',
  },
  {
    Companies: 'Danaher Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Dara BioSciences-acquired by Midatech in 2015',
    Region: 'USA',
  },
  {
    Companies: 'DBV Technologies S.A.',
    Region: 'France',
  },
  {
    Companies: 'Deciphera Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Denali Therapeutics Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Dermira',
    Region: 'USA',
  },
  {
    Companies: 'DermTech',
    Region: 'USA',
  },
  {
    Companies: 'Desert Harvest ',
    Region: 'USA',
  },
  {
    Companies: 'DiaMedica Therapeutics Inc.',
    Region: 'USA',
  },
  {
    Companies: 'DiaSorin',
    Region: 'Italy',
  },
  {
    Companies: 'Dicerna Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Diffusion Pharmaceuticals Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Direct Biologics',
    Region: 'USA',
  },
  {
    Companies: 'Dova Pharmaceuticals ',
    Region: 'USA',
  },
  {
    Companies: "Dr. Reddy's",
    Region: 'India',
  },
  {
    Companies: 'Dragonfly Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Durect ',
    Region: 'USA',
  },
  {
    Companies: 'Dynavax Technologies Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Eagle Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Eargo',
    Region: 'USA',
  },
  {
    Companies: 'Editas Medicine',
    Region: 'USA',
  },
  {
    Companies: 'Edwards Lifesciences',
    Region: 'USA',
  },
  {
    Companies: 'Eiger BioPharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Eisai',
    Region: 'Japan',
  },
  {
    Companies: 'Eli Lilly',
    Region: 'USA',
  },
  {
    Companies: 'Eloxx Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'EMD Serono',
    Region: 'USA',
  },
  {
    Companies: 'Emergent BioSolutions',
    Region: 'USA',
  },
  {
    Companies: 'Enanta ',
    Region: 'USA',
  },
  {
    Companies: 'Endo International',
    Region: 'Ireland',
  },
  {
    Companies: 'Endosound',
    Region: 'USA',
  },
  {
    Companies: 'Enochian Biosciences, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Entasis Therapeutics Holdings Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Entera Bio Ltd',
    Region: 'Israel',
  },
  {
    Companies: 'EntreMed, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Enzyvant',
    Region: 'USA',
  },
  {
    Companies: 'Epizyme, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Equillium, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'ERYTECH Pharma S.A.',
    Region: 'France',
  },
  {
    Companies: 'Esperion ',
    Region: 'USA',
  },
  {
    Companies: 'ESSA Pharma Inc.',
    Region: 'Canada',
  },
  {
    Companies: 'Esteve',
    Region: 'Spain',
  },
  {
    Companies: 'Eton Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'EUSA Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Eva Pharma',
    Region: 'Egypt',
  },
  {
    Companies: 'Evelo Biosciences, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Everest Medicines',
    Region: 'China',
  },
  {
    Companies: 'Everly Well, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Eversana',
    Region: 'USA',
  },
  {
    Companies: 'Evofem Biosciences, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Evoke Pharma, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Evolus, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Evotec',
    Region: 'Germany',
  },
  {
    Companies: 'Exact Sciences Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Exagen',
    Region: 'USA',
  },
  {
    Companies: 'Exelixis',
    Region: 'USA',
  },
  {
    Companies: 'Exicure',
    Region: 'USA',
  },
  {
    Companies: 'Eyegate Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Eyenovia, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'EyePoint Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Fate Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Fennec Pharmaceuticals Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Ferring Pharmaceuticals',
    Region: 'Switzerland',
  },
  {
    Companies: 'FibroGen, Inc',
    Region: 'USA',
  },
  {
    Companies: 'First Wave BioPharma',
    Region: 'USA',
  },
  {
    Companies: 'Five Prime Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Flexion Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Foamix Pharmaceuticals ',
    Region: 'USA',
  },
  {
    Companies: 'Forge Biologics',
    Region: 'USA',
  },
  {
    Companies: 'Forma Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Fortress Biotech, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Fosun Pharma',
    Region: 'China',
  },
  {
    Companies: 'Fractyl Health, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Frequency Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Fresenius Medical Care',
    Region: 'Germany',
  },
  {
    Companies: 'Fulcrum Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'G1 Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Gala Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Galapagos',
    Region: 'Belgium',
  },
  {
    Companies: 'Galderma',
    Region: 'Switzerland',
  },
  {
    Companies: 'Galectin Therapeutics Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Galenica',
    Region: 'Switzerland',
  },
  {
    Companies: 'Galera Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Galmed Pharmaceuticals Ltd.',
    Region: 'Israel',
  },
  {
    Companies: 'Gamida Cell Ltd.',
    Region: 'Israel',
  },
  {
    Companies: 'GC Pharma',
    Region: 'S. Korea',
  },
  {
    Companies: 'GE Healthcare',
    Region: 'USA',
  },
  {
    Companies: 'Gedeon Richter',
    Region: 'Hungary',
  },
  {
    Companies: 'Geisinger',
    Region: 'USA',
  },
  {
    Companies: 'Gelesis',
    Region: 'USA',
  },
  {
    Companies: 'Genentech',
    Region: 'USA',
  },
  {
    Companies: 'Genmab',
    Region: 'Denmark',
  },
  {
    Companies: 'GenMark',
    Region: 'USA',
  },
  {
    Companies: 'Genocea Biosciences, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Genprex, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Genscript Biotech Corp',
    Region: 'USA',
  },
  {
    Companies: 'George Clinical',
    Region:
      'Australia                                                                                                                                                                                                ',
  },
  {
    Companies: 'Geron Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Gilead Sciences',
    Region: 'USA',
  },
  {
    Companies: 'Gland Pharma',
    Region: 'India',
  },
  {
    Companies: 'Glaukos Corp',
    Region: 'USA',
  },
  {
    Companies: 'GlaxoSmithKline (GSK)',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Global Blood Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'GlycoMimetics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Gossamer Bio',
    Region: 'USA',
  },
  {
    Companies: 'Green Cross Essential Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Grifols',
    Region: 'Spain',
  },
  {
    Companies: 'Gr\u00c3\u00bcnenthal',
    Region: 'Germany',
  },
  {
    Companies: 'Guardant Health',
    Region: 'USA',
  },
  {
    Companies: 'GW Pharmaceuticals',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Halozyme Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Hancock Jaffe Laboratories',
    Region: 'USA',
  },
  {
    Companies: 'Hanmi Pharmaceutical',
    Region: 'S. Korea',
  },
  {
    Companies: 'Hansoh Pharmaceutical Group',
    Region: 'China',
  },
  {
    Companies: 'Happiness Biotech Group Limited',
    Region: 'China',
  },
  {
    Companies: 'Harmony Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Harpoon Therapeutics, Inc',
    Region: 'USA',
  },
  {
    Companies: 'Heat Biologics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Helius Medical',
    Region: 'USA',
  },
  {
    Companies: 'Hepion ',
    Region: 'USA',
  },
  {
    Companies: 'Heron Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Hisamitsu Pharmaceutical Co., Inc.',
    Region: 'Japan',
  },
  {
    Companies: 'Histogen Inc.',
    Region: 'USA',
  },
  {
    Companies: 'HOOKIPA Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Horizon Therapeutics',
    Region: 'Ireland',
  },
  {
    Companies: 'Hualan Biological Engineering',
    Region: 'China',
  },
  {
    Companies: 'Hyalex Orthopaedics',
    Region: 'USA',
  },
  {
    Companies: 'ICAD',
    Region: 'USA',
  },
  {
    Companies: 'ICON plc',
    Region: 'Ireland',
  },
  {
    Companies: 'IDEAYA Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Idera Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'IGM Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Illumina, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Immunocore',
    Region: 'United Kingdom',
  },
  {
    Companies: 'ImmunoGen, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Immunomedics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'ImmunoMet Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Immuron Limited',
    Region: 'Australia',
  },
  {
    Companies: 'Immutep Limited',
    Region: 'Australia',
  },
  {
    Companies: 'ImmVira',
    Region: 'China',
  },
  {
    Companies: 'Impel NeuroPharma Inc. ',
    Region: 'USA',
  },
  {
    Companies: 'IMV Inc.',
    Region: 'Canada',
  },
  {
    Companies: 'Incyte Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Indivior',
    Region: 'USA',
  },
  {
    Companies: 'Infinity Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'InflaRx N.V.',
    Region: 'Germany',
  },
  {
    Companies: 'Inhibikase Therapeutics,',
    Region: 'USA',
  },
  {
    Companies: 'INmune Bio',
    Region: 'USA',
  },
  {
    Companies: 'Innate Pharma ',
    Region: 'France',
  },
  {
    Companies: 'Innocoll',
    Region: 'USA',
  },
  {
    Companies: 'Innovate Biopharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Innovive Health',
    Region: 'USA',
  },
  {
    Companies: 'Inova ',
    Region: 'USA',
  },
  {
    Companies: 'Inozyme Pharma, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Insignia',
    Region: 'USA',
  },
  {
    Companies: 'Insmed, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Inspire Medical Systems Inc',
    Region: 'USA',
  },
  {
    Companies: 'Insulet ',
    Region: 'USA',
  },
  {
    Companies: 'Insys Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Intec Pharma Ltd.',
    Region: 'Israel',
  },
  {
    Companies: 'Intelivation Technologies',
    Region: 'USA',
  },
  {
    Companies: 'Intellia Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Intercept Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Intra-Cellular Therapies Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Intrexon Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Intrinsic Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'InVivo Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Ionis Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Ipca Laboratories',
    Region: 'India',
  },
  {
    Companies: 'Ipsen',
    Region: 'France',
  },
  {
    Companies: 'IQone Healthcare',
    Region: 'Switzerland',
  },
  {
    Companies: 'IQVIA',
    Region: 'USA',
  },
  {
    Companies: 'Janssen Pharmaceuticals',
    Region: 'Belgium',
  },
  {
    Companies: 'JAR of Hope',
    Region: 'USA',
  },
  {
    Companies: 'Jazz Pharmaceuticals',
    Region: 'Ireland',
  },
  {
    Companies: 'JenaValve',
    Region: 'USA',
  },
  {
    Companies: 'Jiangsu Hengrui Medicine',
    Region: 'China',
  },
  {
    Companies: 'Johnson & Johnson',
    Region: 'USA',
  },
  {
    Companies: 'Junshi Biosciences',
    Region: 'China',
  },
  {
    Companies: 'Kala Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Karius',
    Region: 'USA',
  },
  {
    Companies: 'Karuna Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Karyopharm',
    Region: 'USA',
  },
  {
    Companies: 'Kedrion',
    Region: 'USA',
  },
  {
    Companies: 'KemPharm',
    Region: 'USA',
  },
  {
    Companies: 'Kibow Biotech',
    Region: 'USA',
  },
  {
    Companies: 'Kintara Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Kite pharma',
    Region: 'USA',
  },
  {
    Companies: 'KOBAYASHI Pharmaceutical',
    Region: 'Japan',
  },
  {
    Companies: 'Kowa',
    Region: 'Japan',
  },
  {
    Companies: 'Krka',
    Region: 'Slovenia',
  },
  {
    Companies: 'Kyorin',
    Region: 'Japan',
  },
  {
    Companies: 'kyowa Kirin',
    Region: 'Japan',
  },
  {
    Companies: 'LabCorp',
    Region: 'USA',
  },
  {
    Companies: 'Laboratoires Pierre Fabre',
    Region: 'France',
  },
  {
    Companies: 'Laboratoires Servier',
    Region: 'France',
  },
  {
    Companies: 'Landos Biopharma',
    Region: 'USA',
  },
  {
    Companies: 'Lantern Pharma ',
    Region: 'USA',
  },
  {
    Companies: 'Lantheus',
    Region: 'USA',
  },
  {
    Companies: 'Laurus Labs',
    Region: 'India',
  },
  {
    Companies: 'Legend Biotech',
    Region: 'USA',
  },
  {
    Companies: 'Lenco Diagnostic Laboratory',
    Region: 'USA',
  },
  {
    Companies: 'Leo Pharma',
    Region: 'Denmark',
  },
  {
    Companies: 'LetsGetChecked',
    Region: 'USA',
  },
  {
    Companies: 'Lexeo Therapeutics ',
    Region: 'USA',
  },
  {
    Companies: 'Life Molecular Imaging',
    Region: 'Germany',
  },
  {
    Companies: 'Liminal BioSciences, Inc.',
    Region: 'Canada',
  },
  {
    Companies: 'Linnaeus Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Livzon Pharm',
    Region: 'China',
  },
  {
    Companies: 'Lonza',
    Region: 'Switzerland',
  },
  {
    Companies: 'Lorus Therapeutics, Inc.',
    Region: 'Canada',
  },
  {
    Companies: 'Lundbeck',
    Region: 'Denmark',
  },
  {
    Companies: 'Lundbeck Seattle Biopharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Lupin Limited',
    Region: 'India',
  },
  {
    Companies: 'Lyndra Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Mallinckrodt',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Masimo',
    Region: 'USA',
  },
  {
    Companies: 'Meda',
    Region: 'USA',
  },
  {
    Companies: 'MedAlliance',
    Region: 'USA',
  },
  {
    Companies: 'Medday Pharmaceuticals',
    Region: 'France',
  },
  {
    Companies: 'MedImmune',
    Region: 'USA',
  },
  {
    Companies: 'Medtronic',
    Region: 'Ireland',
  },
  {
    Companies: 'MEI Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Meiji Holdings Co., Ltd.',
    Region: 'China',
  },
  {
    Companies: 'Melinta Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Menarini',
    Region: 'Italy',
  },
  {
    Companies: 'Merck',
    Region: 'USA',
  },
  {
    Companies: 'Merit Medical Systems, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Merz Pharma GmbH & Co. KGaA',
    Region: 'Germany',
  },
  {
    Companies: 'Merz Therapeutics North America',
    Region: 'USA',
  },
  {
    Companies: 'Mesoblast Limited',
    Region: 'Australia',
  },
  {
    Companies: 'MicroTransponder ',
    Region: 'USA',
  },
  {
    Companies: 'Mirati',
    Region: 'USA',
  },
  {
    Companies: 'MitoChem Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Mitsubishi Tanabe Pharma',
    Region: 'China',
  },
  {
    Companies: 'Mitsubishi Tanabe U.S.',
    Region: 'USA',
  },
  {
    Companies: 'Moberg Pharma',
    Region: 'Sweden',
  },
  {
    Companies: 'Moderna',
    Region: 'USA',
  },
  {
    Companies: 'Molecular Templates',
    Region: 'USA',
  },
  {
    Companies: 'Moleculin Biotech',
    Region: 'USA',
  },
  {
    Companies: 'M\u00c3\u00b6lnlycke Health Care',
    Region: 'Sweden',
  },
  {
    Companies: 'MorphoSys',
    Region: 'Germany',
  },
  {
    Companies: 'MSN Labs',
    Region: 'USA',
  },
  {
    Companies: 'Mundipharma Deutschland GmbH & Co. KG',
    Region: 'Germany',
  },
  {
    Companies: 'Mylan',
    Region: 'USA',
  },
  {
    Companies: 'MyMD Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Myovant Sciences',
    Region: 'Switzerland',
  },
  {
    Companies: 'Myriad Neurosciences',
    Region: 'USA',
  },
  {
    Companies: 'Nanobiotix',
    Region: 'France',
  },
  {
    Companies: 'NANTHEALTH',
    Region: 'USA',
  },
  {
    Companies: 'NantKwest',
    Region: 'USA',
  },
  {
    Companies: 'Natco Pharma',
    Region: 'India',
  },
  {
    Companies: 'Natera',
    Region: 'USA',
  },
  {
    Companies: 'Nektar Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'NeoImmuneTech Inc',
    Region: 'USA',
  },
  {
    Companies: 'Neoleukin Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Nestl\u00c3\u00a9 Health Science S.A.',
    Region: 'Switzerland',
  },
  {
    Companies: 'Neuraptive Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Neurelis',
    Region: 'USA',
  },
  {
    Companies: 'Neurogene',
    Region: 'USA',
  },
  {
    Companies: 'Nexus Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'NFlection Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Nichi-Iko Pharmaceutical',
    Region: 'Japan',
  },
  {
    Companies: 'Nippon Shinyaku',
    Region: 'Japan',
  },
  {
    Companies: 'Nissha Medical Technologies',
    Region: 'USA',
  },
  {
    Companies: 'Nkarta',
    Region: 'USA',
  },
  {
    Companies: 'Novartis',
    Region: 'Switzerland',
  },
  {
    Companies: 'Novartis Gene Therapies',
    Region: 'USA',
  },
  {
    Companies: 'Novo Nordisk',
    Region: 'Denmark',
  },
  {
    Companies: 'Novocure',
    Region: 'USA',
  },
  {
    Companies: 'NS Pharma, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Nuvation Bio ',
    Region: 'USA',
  },
  {
    Companies: 'NUWELLIS',
    Region: 'USA',
  },
  {
    Companies: 'ObsEva',
    Region: 'Switzerland',
  },
  {
    Companies: 'Occlutech ',
    Region: 'Sweden',
  },
  {
    Companies: 'Octapharma',
    Region: 'USA',
  },
  {
    Companies: 'Olympus Corporation',
    Region: 'Japan',
  },
  {
    Companies: 'Ono Pharmaceutical',
    Region: 'Japan',
  },
  {
    Companies: 'OPKO Health',
    Region: 'USA',
  },
  {
    Companies: 'Optellum',
    Region: 'United Kingdom',
  },
  {
    Companies: 'OptiNose',
    Region: 'USA',
  },
  {
    Companies: 'Orchard Therapeutics',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Ordaos Bio',
    Region: 'USA',
  },
  {
    Companies: 'Organicell Regenerative Medicine',
    Region: 'USA',
  },
  {
    Companies: 'Organogenesis',
    Region: 'USA',
  },
  {
    Companies: 'Organon',
    Region: 'USA',
  },
  {
    Companies: 'Orion Corporation',
    Region: 'Finland',
  },
  {
    Companies: 'Orphazyme',
    Region: 'Denmark',
  },
  {
    Companies: 'Ortho Clinical Diagnostics',
    Region: 'USA',
  },
  {
    Companies: 'Oryzon',
    Region: 'Spain',
  },
  {
    Companies: 'Otsuka Holdings',
    Region: 'Japan',
  },
  {
    Companies: 'Ovid Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Oyster Point Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Paradigm Biopharmaceuticals',
    Region: 'Australia',
  },
  {
    Companies: 'ParaPRO',
    Region: 'USA',
  },
  {
    Companies: 'Passage Bio',
    Region: 'USA',
  },
  {
    Companies: 'PeptiDream',
    Region: 'Japan',
  },
  {
    Companies: 'PerkinElmer',
    Region: 'USA',
  },
  {
    Companies: 'Perrigo',
    Region: 'Ireland',
  },
  {
    Companies: 'Pfizer',
    Region: 'USA',
  },
  {
    Companies: 'Pharmacosmos ',
    Region: 'Denmark',
  },
  {
    Companies: 'Pharmscript',
    Region: 'USA',
  },
  {
    Companies: 'Philip Morris International',
    Region: 'USA',
  },
  {
    Companies: 'Phillips Pharmaceuticals Limited',
    Region: 'Kenya',
  },
  {
    Companies: 'Photocure',
    Region: 'Norway',
  },
  {
    Companies: 'Piramal Group',
    Region: 'India',
  },
  {
    Companies: 'Poseida Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'PPD',
    Region: 'USA',
  },
  {
    Companies: 'Precigen',
    Region: 'USA',
  },
  {
    Companies: 'Prelude Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Premia Spine',
    Region: 'USA',
  },
  {
    Companies: 'Procter & Gamble',
    Region: 'USA',
  },
  {
    Companies: 'Progenity',
    Region: 'USA',
  },
  {
    Companies: 'Promaxo Inc. ',
    Region: 'USA',
  },
  {
    Companies: 'Protara Therapeutics, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'PsiGenex',
    Region: 'USA',
  },
  {
    Companies: 'Puzzle Medical Devices',
    Region: 'Canada',
  },
  {
    Companies: 'QLB Biotherapeutics Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Quadrant Biosciences',
    Region: 'USA',
  },
  {
    Companies: 'Quest Diagnostics',
    Region: 'USA',
  },
  {
    Companies: 'Quoin Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Radiomedix, Inc. ',
    Region: 'USA',
  },
  {
    Companies: 'Radius Health',
    Region: 'USA',
  },
  {
    Companies: 'Rakuten Medical',
    Region: 'USA',
  },
  {
    Companies: 'RDD Pharma, Ltd.',
    Region: 'Israel',
  },
  {
    Companies: 'Reata Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Reckitt',
    Region: 'United Kingdom',
  },
  {
    Companies: 'ReCor Medical',
    Region: 'USA',
  },
  {
    Companies: 'Recordati',
    Region: 'Italy',
  },
  {
    Companies: 'Regeneron Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Respinova',
    Region: 'Israel',
  },
  {
    Companies: 'Rigel Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Roche',
    Region: 'Switzerland',
  },
  {
    Companies: 'Roche Diagnostics USA',
    Region: 'USA',
  },
  {
    Companies: 'Roche EGYPT',
    Region: '',
  },
  {
    Companies: 'Rocket Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Roivant Sciences',
    Region: 'Switzerland',
  },
  {
    Companies: 'Royal Biologics ',
    Region: 'USA',
  },
  {
    Companies: 'Samsung Biologics',
    Region: 'S. Korea',
  },
  {
    Companies: 'Sandoz: (Parent organization: Novartis)',
    Region: 'Switzerland',
  },
  {
    Companies: 'Sanofi',
    Region: 'France',
  },
  {
    Companies: 'Santen Pharmaceutical',
    Region: 'Japan',
  },
  {
    Companies: 'Sarepta Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Savara',
    Region: 'USA',
  },
  {
    Companies: 'SAWAI PHARMACEUTICAL CO., LTD.',
    Region: 'Japan',
  },
  {
    Companies: 'scPharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Seattle Genetics',
    Region: 'USA',
  },
  {
    Companies: 'Seqirus UK Limited',
    Region: 'Australia',
  },
  {
    Companies: 'Servier ME',
    Region: '',
  },
  {
    Companies: 'Sesen Bio',
    Region: 'USA',
  },
  {
    Companies: 'Shanghai Pharmaceuticals',
    Region: 'China',
  },
  {
    Companies: 'Shijiazhuang Pharma Group',
    Region: 'China',
  },
  {
    Companies: 'Shinpoong Pharm',
    Region: 'S. Korea',
  },
  {
    Companies: 'Shionogi',
    Region: 'Japan',
  },
  {
    Companies: 'Sichuan KELUN PHARMACEUTICAL Co., Ltd.',
    Region: 'China',
  },
  {
    Companies: 'Siemens',
    Region: 'Germany',
  },
  {
    Companies: 'Sigilon Therapeutics ',
    Region: 'USA',
  },
  {
    Companies: 'Signifier Medical Technologies',
    Region: 'Ireland',
  },
  {
    Companies: 'Sino Biopharmaceutical Limited',
    Region: 'China',
  },
  {
    Companies: 'Sio Gene Therapies',
    Region: 'USA',
  },
  {
    Companies: 'Sirnaomics',
    Region: 'USA',
  },
  {
    Companies: 'SK Biopharmaceuticals Co',
    Region: 'China',
  },
  {
    Companies: 'Smith & Nephew',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Sol Gel Technologies Ltd',
    Region: 'Israel',
  },
  {
    Companies: 'Soliton',
    Region: 'USA',
  },
  {
    Companies: 'Sorrento Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Soundbite Medical Solution',
    Region: 'Canada',
  },
  {
    Companies: 'Spectral Medical',
    Region: 'Canada',
  },
  {
    Companies: 'Spectrum Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Spero Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'SphingoTec',
    Region: 'Germany',
  },
  {
    Companies: 'Stada Arzneimittel',
    Region: 'Germany',
  },
  {
    Companies: 'Stoke Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Straumann',
    Region: 'Switzerland',
  },
  {
    Companies: 'Strongbridge Bio',
    Region: 'USA',
  },
  {
    Companies: 'Stryker Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Sumitomo Dainippon Pharma',
    Region: 'Japan',
  },
  {
    Companies: 'Sun Pharmaceutical Industries Ltd.',
    Region: 'India',
  },
  {
    Companies: 'Sunovion Pharmaceuticals Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Supernus Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Sutro Biopharma ',
    Region: 'USA',
  },
  {
    Companies: 'Swedish Orphan Biovitrum',
    Region: 'Sweden',
  },
  {
    Companies: 'Swing Therapeutics ',
    Region: 'USA',
  },
  {
    Companies: 'Synapse',
    Region: 'USA',
  },
  {
    Companies: 'Syndax',
    Region: 'USA',
  },
  {
    Companies: 'Syneos Health',
    Region: 'USA',
  },
  {
    Companies: 'Taiho Oncology Inc',
    Region: 'USA',
  },
  {
    Companies: 'Taisho',
    Region: 'USA',
  },
  {
    Companies: 'Taisho Pharmaceutical',
    Region: 'Japan',
  },
  {
    Companies: 'Takeda Pharmaceutical',
    Region: 'Japan',
  },
  {
    Companies: 'Tarus Therapeutic',
    Region: 'USA',
  },
  {
    Companies: 'Teijin',
    Region: 'Japan',
  },
  {
    Companies: 'Teleflex',
    Region: 'USA',
  },
  {
    Companies: 'TerSera Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Terumo Corporation',
    Region: 'Japan',
  },
  {
    Companies: 'Tesaro',
    Region: 'USA',
  },
  {
    Companies: 'Teva Pharmaceutical Industries',
    Region: 'Israel',
  },
  {
    Companies: 'TG Therapeutics, Inc',
    Region: 'USA',
  },
  {
    Companies: 'The George Institute For Global Health',
    Region: 'New Zealand',
  },
  {
    Companies: 'The Medical Affairs Company',
    Region: 'USA',
  },
  {
    Companies: 'TherapeuticsMD',
    Region: 'USA',
  },
  {
    Companies: 'Theratechnologies',
    Region: 'Canada',
  },
  {
    Companies: 'Theravance',
    Region: 'USA',
  },
  {
    Companies: 'Thermo Fisher Scientific',
    Region: 'USA',
  },
  {
    Companies: 'Tilray',
    Region: 'Canada/USA',
  },
  {
    Companies: 'Torrent Pharmaceuticals',
    Region: 'India',
  },
  {
    Companies: 'TransMedics Group ',
    Region: 'USA',
  },
  {
    Companies: 'Travere Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Turning Point',
    Region: 'USA',
  },
  {
    Companies: 'UCB',
    Region: 'Belgium',
  },
  {
    Companies: 'Ultimed Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Ultragenyx',
    Region: 'USA',
  },
  {
    Companies: 'United Therapeutics Corporation',
    Region: 'USA',
  },
  {
    Companies: 'Upsher-Smith Laboratories',
    Region: 'USA',
  },
  {
    Companies: 'Upstate University Hospital',
    Region: 'USA',
  },
  {
    Companies: 'Urogen Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Urovant',
    Region: 'USA',
  },
  {
    Companies: 'UVision360 Inc. is under LUMINELLE DTx',
    Region: 'USA',
  },
  {
    Companies: 'Valneva SE',
    Region: 'France',
  },
  {
    Companies: 'Varian Clinical Evaluations Team',
    Region: 'USA',
  },
  {
    Companies: 'Varian Medical Systems',
    Region: 'USA',
  },
  {
    Companies: 'Varian Medical Systems UK',
    Region: 'United Kingdom',
  },
  {
    Companies: 'Vaxart, Inc',
    Region: 'USA',
  },
  {
    Companies: 'Vect-Horus ',
    Region: 'France',
  },
  {
    Companies: 'Veloxis',
    Region: 'USA',
  },
  {
    Companies: 'VERALOX',
    Region: 'USA',
  },
  {
    Companies: 'Vericel',
    Region: 'USA',
  },
  {
    Companies: 'Verrica',
    Region: 'USA',
  },
  {
    Companies: 'Vertex Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Viatris',
    Region: 'USA',
  },
  {
    Companies: 'Viela Bio',
    Region: 'USA',
  },
  {
    Companies: 'Vifor Pharma',
    Region: 'Switzerland',
  },
  {
    Companies: 'ViiV Healthcare Limited',
    Region: 'USA',
  },
  {
    Companies: 'Vincerx Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Vir Biotechnology',
    Region: 'USA',
  },
  {
    Companies: 'Viracta Therapeutics, Inc, ',
    Region: 'USA',
  },
  {
    Companies: 'Vivos therapeutics ',
    Region: 'USA',
  },
  {
    Companies: 'VOLUNTIS S.A. EP-,10',
    Region: 'France',
  },
  {
    Companies: 'Voyager Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'vTv Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'VYNE Therapeutics',
    Region: 'USA',
  },
  {
    Companies: 'Walgreens Boots Alliance',
    Region: 'USA',
  },
  {
    Companies: 'Wave Life Science',
    Region: 'USA',
  },
  {
    Companies: 'West Pharma',
    Region: 'USA',
  },
  {
    Companies: 'Wuhan Humanwell Hi-tech Ind. Co., Ltd.',
    Region: 'China',
  },
  {
    Companies: 'WuXi AppTec',
    Region: 'China',
  },
  {
    Companies: 'X4 Pharmaceuticals',
    Region: 'USA',
  },
  {
    Companies: 'Xcenda',
    Region: 'USA',
  },
  {
    Companies: 'Xellia',
    Region: 'Denmark',
  },
  {
    Companies: 'Xeltis',
    Region: 'Switzerland',
  },
  {
    Companies: 'Xeris Pharmaceuticals, Inc.',
    Region: 'USA',
  },
  {
    Companies: 'Xilio ',
    Region: 'USA',
  },
  {
    Companies: 'Yuhan',
    Region: 'S. Korea',
  },
  {
    Companies: 'Yunnan Baiyao Group',
    Region: 'China',
  },
  {
    Companies: 'Zambon',
    Region: 'Italy',
  },
  {
    Companies: 'Zeiss',
    Region: 'Germany',
  },
  {
    Companies: 'Zhejiang Hisun',
    Region: 'China',
  },
  {
    Companies: 'Zimmer Biomet',
    Region: 'USA',
  },
  {
    Companies: 'Zogenix',
    Region: 'USA',
  },
  {
    Companies: 'Zuellig',
    Region: 'Hong Kong',
  },
  {
    Companies: 'Other/Not Listed',
    Region: 'Other/Not Listed',
  },
];
