// routes
import { Toaster } from 'react-hot-toast';

import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import './assets/styles/generic.css';
import './assets/styles/main.css';
// import EmailDialog from './components/EmailPopup';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <Toaster />
      <Router />
    </ThemeProvider>
  );
}
