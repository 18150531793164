import { useState } from 'react';

import {
  Alert,
  AlertTitle,
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useGetComplaints from '../../hooks/useGetComplaints';
import { companies } from '../../_mock/companies';
import useResponsive from '../../hooks/useResponsive';

export default function ViewComplaints() {
  const { data, error, loading } = useGetComplaints();
  const [selectedValue, setSelectedValue] = useState('');
  const isDesktop = useResponsive('up', 'md');

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Container>
      <Helmet>
        <title>View Complaints</title>
        <meta
          name="description"
          content="Find out what others are saying about pharmaceutical companies. Browse our platform to view complaints and reviews from real people. Get the inside scoop and make informed decisions about your healthcare."
        />
        <meta
          name="og:image"
          content={
            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/complaintPortal/Hexagon+Compressed.png'
          }
        />
      </Helmet>
      {error ? (
        <>
          <Card>
            <CardContent>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Error getting complaints! Please refresh the page or contact us.
              </Alert>
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <div className="mt-3 mb-5">
            <Typography variant="h3">Complaints</Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item md={4} sx={{ width: '100%' }}>
              {isDesktop ? (
                <>
                  {' '}
                  <Card sx={{ height: 300 }}>
                    <CardContent sx={{ height: 300 }}>
                      <Typography variant="h6">Filters</Typography>
                      <div className="flex w-100 mt-5 mb-5">
                        <Autocomplete
                          onChange={(e, v) => setSelectedValue(v)}
                          value={selectedValue}
                          freeSolo
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={companies.map((option) => option.Companies)}
                          renderInput={(params) => <TextField {...params} label="Search Company" />}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </>
              ) : (
                <>
                  <div className="flex w-100 mt-7 mb-5">
                    <Autocomplete
                      onChange={(e, v) => setSelectedValue(v)}
                      value={selectedValue}
                      freeSolo
                      fullWidth
                      disablePortal
                      id="combo-box-demo"
                      options={companies.map((option) => option.Companies)}
                      renderInput={(params) => <TextField {...params} label="Search Company" />}
                    />
                  </div>
                </>
              )}
            </Grid>
            <Grid item md={8} width="100%">
              <Card width="100%">
                <CardContent>
                  <Typography variant="h6">Most Recent Complaints</Typography>
                  <div className="mt-4">
                    {data && data.length >= 1 ? (
                      data
                        .filter((item) => !selectedValue || item.companyName.includes(selectedValue))
                        .map((item) => (
                          <>
                            <Card className="mt-2 mb-2">
                              <CardContent>
                                <Typography sx={{ fontSize: '12px' }}>{item.entryDate}</Typography>
                                <Divider sx={{ mt: 2, mb: 2 }} />
                                <Grid container spacing={2}>
                                  <Grid item md={6}>
                                    <List>
                                      <ListItem disablePadding>
                                        <ListItemIcon>
                                          <AccountCircleIcon fontSize="large" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={
                                            <>
                                              <Typography>
                                                <b>{item.companyName}</b>
                                              </Typography>
                                              <Typography sx={{ fontSize: '12px' }}>{item.complainDate}</Typography>
                                            </>
                                          }
                                        />
                                      </ListItem>
                                    </List>
                                  </Grid>
                                  <Grid item md={6} sx={{ marginTop: '10px' }}>
                                    <div>
                                      <Typography sx={{ textAlign: 'end' }}>
                                        <b>{item.complainType}</b>
                                      </Typography>
                                      <Typography sx={{ textAlign: 'end', fontSize: '12px' }}>
                                        {item.complainNature}
                                      </Typography>
                                    </div>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </>
                        ))
                        .reverse()
                    ) : (
                      <>
                        <Typography>No Complaints Found</Typography>
                      </>
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}
