import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
import DashboardLayout from './layouts/dashboard';
//
import Page404 from './pages/Page404';
import Home from './pages/home';
import Complaint from './pages/complaint';
import Success from './pages/success';
import ViewComplaints from './pages/viewComplaints';
import Blogs from './pages/Blogs';
import Post from './pages/Blogs/Post';
import Faqs from './pages/faqs';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/complaint', element: <Complaint /> },
        { path: '/success', element: <Success /> },
        { path: '/view-complaints', element: <ViewComplaints /> },
        { path: '/blogs', element: <Blogs /> },
        { path: '/blog/:slug', element: <Post /> },
        { path: '/faqs', element: <Faqs /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
