import { Alert, AlertTitle, Grid, TextField, Typography } from '@mui/material';
import { useContext } from 'react';
import { CompanyContext } from './CompanyStepper';

export default function Step1() {
  const { userName, setUserName, email, setEmail } = useContext(CompanyContext);

  return (
    <>
      <div className="mt-2 mb-2">
        <Typography variant="h4">Tell us about yourself</Typography>
      </div>
      <Alert severity="success">
        <AlertTitle>Privacy</AlertTitle>
        At ACMA, we value your privacy. Check the{' '}
        <a href="https://medicalaffairsspecialist.org/policy/privacy" target="_blank" rel="noreferrer">
          ACMA Privacy Policy
        </a>
      </Alert>
      <form className="mt-5">
        <Grid spacing={3} container>
          <Grid md={12} item width="100%">
            <TextField
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
              label="Display Name (Optional)"
              variant="outlined"
              helperText="Your Full Name or Your Organization's Name"
            />
          </Grid>
          <Grid md={12} item width="100%">
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              label="Your Email (Optional)"
              variant="outlined"
              helperText="Your Email or Your Organization's Email"
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
