import { useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { Helmet } from 'react-helmet';
// import BannerImage from '../../../public/assets/images/covers/MainBanner.png';
import BannerImage from '../../assets/images/covers/MainBanner.png';
import useResponsive from '../../hooks/useResponsive';
import { COMPLAINTS_PAGE_URL, VIEW_COMPLAINTS_URL } from '../../constants/urls';

import Blogs from '../../components/modules/Blogs';
import { companies } from '../../_mock/companies';
import { PHARMA_IMAGE } from '../../constants/images';
import TimelineSteps from '../../components/modules/TimelineSteps';

export default function Home() {
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('');
  const [otherValue, setOtherValue] = useState(null);

  // Handle Click
  const handleClick = () => {
    if (selectedValue) {
      navigate(COMPLAINTS_PAGE_URL, {
        state: {
          company: { selectedValue, otherValue },
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>ACMA Complaint Portal</title>
        <meta
          name="description"
          content="Elevate pharmaceutical care with ease and safety through submitting, investigating, and continuously enhancing with our all-inclusive complaint reporting platform."
        />
        <meta
          name="og:image"
          content={
            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/complaintPortal/Hexagon+Compressed.png'
          }
        />
      </Helmet>
      <div className={isDesktop ? 'pt-10 pl-18 pr-18 pb-18 rounded-xlg' : ''}>
        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} className="flex justify-center align-center column">
              <Typography variant="h2">
                Report Suspicious <span style={{ color: theme.palette.primary.main }}>Pharmaceutical</span> Industry
                Activities
              </Typography>
              <Typography variant="subtitle" className="mt-5">
                {/* Elevate pharmaceutical care with ease and safety through submitting, investigating, and continuously
                enhancing with our all-inclusive complaint reporting platform. */}
                Have you observed or encountered unethical behavior by a pharmaceutical sales representative, a medical
                science liaison or a pharmaceutical industry employee? Help the Accreditation Council for Medical
                Affairs (ACMA) raise the bar and improve accountability for the pharmaceutical & biotechnology industry.
              </Typography>
              <div className="flex w-100 mt-5">
                <Autocomplete
                  onChange={(e, v) => setSelectedValue(v)}
                  value={selectedValue}
                  freeSolo
                  fullWidth
                  disablePortal
                  id="combo-box-demo"
                  options={companies.map((option) => option.Companies)}
                  renderInput={(params) => <TextField {...params} label="Select Company" />}
                />
                <Button variant="contained" className="ml-4" onClick={handleClick}>
                  Report
                </Button>
              </div>
              {selectedValue && selectedValue === 'Other/Not Listed' ? (
                <TextField
                  onChange={(e) => setOtherValue(e.target.value)}
                  value={otherValue}
                  sx={{ mt: 2 }}
                  id="outlined-basic"
                  label="Specify other company"
                  variant="outlined"
                  fullWidth
                />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item md={6} className="flex justify-center align-center column" sx={!isDesktop ? { mt: 3 } : {}}>
              <img src={BannerImage} alt="pharma hexImage" loading="lazy" />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container sx={{ mt: 20 }}>
        <div>
          <Typography variant="h3">Ensuring Ethical and Responsible Practices to Hold Pharma Accountable</Typography>
        </div>
        <Grid spacing={3} container sx={{ mt: 3 }}>
          <Grid item md="8" sx={{ width: '100%' }}>
            <Card>
              <CardContent>
                <div className="flex align-center">
                  <CreateIcon />
                  <Typography sx={{ ml: 1 }} variant="h4">
                    File a Complaint
                  </Typography>
                </div>
                <Typography>
                  Need to file a complaint? ACMA is here to help. We'll guide you through the process.
                </Typography>
                <Button
                  className="mt-3"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = COMPLAINTS_PAGE_URL;
                  }}
                >
                  Write a Complaint
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md="4" sx={{ width: '100%' }}>
            <Card>
              <CardContent>
                <div className="flex align-center">
                  <GppMaybeIcon />
                  <Typography sx={{ ml: 1 }} variant="h4">
                    View Complaints
                  </Typography>
                </div>
                <Typography>View complaints from others</Typography>
                <Button
                  className="mt-3"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = VIEW_COMPLAINTS_URL;
                  }}
                >
                  View Complaints
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ mt: 20 }}>
          <TimelineSteps />
        </Box>
      </Container>
      <Blogs />
      <Container>
        <Box sx={{ mt: 20 }}>
          <Typography variant="h3" className="text-center">
            About ACMA
          </Typography>
          <Grid spacing={4} container sx={{ mt: 5 }}>
            <Grid item md={4}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Credible and Reliable
              </Typography>
              <Typography>
                Created out of a need for more transparent quality and competency standards in the biopharma space, the
                Accreditation Council for Medical Affairs (ACMA) has evolved into the only independent accrediting body
                overseeing the life sciences industry. Founded in 2015, the ACMA works across the industry to credential
                individuals and accredit the practices of various companies operating in the arena.
              </Typography>
            </Grid>
            <Grid item md={4} className="flex justify-center aling-center">
              <img src={PHARMA_IMAGE} alt="Complaint" style={{ borderRadius: '15px' }} />
            </Grid>
            <Grid item md={4} sx={{ width: '100%' }}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Our Core Values
              </Typography>
              <div>
                <List sx={{ mt: 2, backgroundColor: 'white', borderRadius: '10px', boxShadow: '10px 5px 5px #c3c3c3' }}>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon sx={{ color: '#76c3a1' }} />
                    </ListItemIcon>
                    <ListItemText primary="Doing what's right" />
                  </ListItem>
                </List>
                <List sx={{ mt: 2, backgroundColor: 'white', borderRadius: '10px', boxShadow: '10px 5px 5px #c3c3c3' }}>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon sx={{ color: '#76c3a1' }} />
                    </ListItemIcon>
                    <ListItemText primary="Challenging the status quo" />
                  </ListItem>
                </List>
                <List sx={{ mt: 2, backgroundColor: 'white', borderRadius: '10px', boxShadow: '10px 5px 5px #c3c3c3' }}>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon sx={{ color: '#76c3a1' }} />
                    </ListItemIcon>
                    <ListItemText primary="Focusing on the community" />
                  </ListItem>
                </List>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
