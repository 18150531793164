import { Box, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import useResponsive from '../../hooks/useResponsive';
import CompanyStepper from './CompanyStepper';

export default function Complaint() {
  const isDesktop = useResponsive('up', 'lg');
  const location = useLocation();
  const { company, otherValue } = location && location.state ? location.state : '';
  const companyValue = company ? (company && company.selectedValue ? company.selectedValue : '') : '';
  const notListedValue = company ? (company && company.otherValue ? company.otherValue : '') : '';

  return (
    <>
      <Helmet>
        <title>Write a Complaint</title>
        <meta
          name="description"
          content="Submit your complaints against pharmaceutical companies on our platform. Hold the industry accountable and make your voice heard. Join our community and share your experiences today."
        />
        <meta
          name="og:image"
          content={
            'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/complaintPortal/Hexagon+Compressed.png'
          }
        />
      </Helmet>
      <div className={isDesktop ? 'pt-10 pl-18 pr-18 pb-18 rounded-xlg' : ''}>
        <Container>
          <Helmet>
            <title> Complaint </title>
          </Helmet>

          <Typography variant="h2">File a Complaint</Typography>
          <Box sx={{ mt: 5 }}>
            <CompanyStepper companyName={companyValue} notListedValue={notListedValue} />
          </Box>
        </Container>
      </div>
    </>
  );
}
