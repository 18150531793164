import {
  Avatar,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Helmet } from 'react-helmet-async';
import useGetBlogPost from '../../hooks/contentful/useGetBlogPosts';
import FileComplaintCTA from '../../components/cta/FileComplaintCTA';

export default function Post() {
  let { slug } = useParams();
  slug = '/'.concat(slug);
  const navigate = useNavigate();
  const { entries, loading, error } = useGetBlogPost(slug);
  console.log(entries && entries);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="blog-description">{children}</p>,

      [BLOCKS.HEADING_3]: (node, children) => {
        return <Typography variant="h3">{children}</Typography>;
      },
    },
  };

  if (loading) {
    return <CircularProgress />;
  }
  if (error || (entries && entries.length === 0)) {
    navigate('/404');
  }
  return (
    <Container sx={{ mt: 3, mb: 3 }}>
      <Helmet>
        <title>{entries.fields.blogTitle}</title>
        <meta name="description" content={entries.fields.metaDescription} />
        <meta name="og:image" content={entries.fields.blogImage.fields.file.url} />
      </Helmet>
      <Grid container spacing={3} className="flex justify-center">
        <Grid item md={9}>
          <div className="flex justify-center align-center">
            <img
              className="rounded-lg"
              src={entries.fields.blogImage.fields.file.url || ''}
              alt={entries.fields.blogTitle || ''}
            />
          </div>
          <div className="text-center mt-10">
            <Typography>{entries.fields.readTime || ''} minutes read</Typography>
            <Typography variant="h3" sx={{ mt: 2 }}>
              {entries.fields.blogTitle || ''}
            </Typography>
          </div>

          <div className="mt-5">
            <Divider sx={{ mb: 2 }} />
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: '#014459', fontSize: '12px' }}>ACMA</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={entries.fields.author || ''}
                  secondary={
                    entries.fields.publishDate
                      ? new Date(entries.fields.publishDate)
                          .toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                          })
                          .concat(', ') + new Date(entries.fields.publishDate).getFullYear()
                      : ''
                  }
                />
              </ListItem>
            </List>
            <Divider sx={{ mt: 2 }} />
          </div>
          <div className="mt-10">{documentToReactComponents(entries.fields.blogDescription, options)}</div>
        </Grid>
      </Grid>
      <FileComplaintCTA />
    </Container>
  );
}
