import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function FileComplaintCTA() {
  const navigate = useNavigate();
  return (
    <Grid sx={{ mt: 5, mb: 2 }} container spacing={2} className="flex justify-center">
      <Grid item md={9}>
        <Card>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <div className="mt-4 flex justify-center aling-center column">
                  <Typography variant="h3">Report Pharmaceutical Misconduct</Typography>
                  <Typography>
                    Our complaint portal provides a reliable platform for individuals to report any pharmaceutical
                    industry misconduct they have witnessed or experienced.
                  </Typography>
                </div>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate('/complaint')}>
                  Report Misconduct
                </Button>
              </Grid>
              <Grid item md={6}>
                <div>
                  <img
                    alt="pharma health"
                    src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/complaintPortal/PharmaHealth.svg"
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
