import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { SUCCESS_THANK_YOU } from '../../constants/images';
import useResponsive from '../../hooks/useResponsive';
import { COMPANY_COMPLAINT_URL, COMPLAINTS_PAGE_URL, HOME_URL } from '../../constants/urls';

export default function Success() {
  const isDesktop = useResponsive('up', 'lg');
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@acmainfo.org';
  };
  return (
    <Container>
      <div className="flex justify-center align-center column">
        <Box sx={isDesktop ? { width: '50%' } : {}}>
          <img src={SUCCESS_THANK_YOU} alt="success" loading="lazy" />
        </Box>
        <Grid spacing={3} container>
          <Grid item md={8}>
            <Card>
              <CardContent>
                <Typography variant="h3" sx={{ mb: 2 }}>
                  Complaint Successfully Submitted
                </Typography>
                <Typography variant="subtitle">
                  Thank you for submitting your complaint. Your feedback is important to us and helps us keep
                  pharmaceutical companies and individuals accountable. We will review your complaint and take
                  appropriate action. If you need to follow up on your complaint, please reach out to us using the
                  contact information provided. Thank you again for speaking up.
                </Typography>
                <div className="mt-3 flex">
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = HOME_URL;
                    }}
                  >
                    Return to Home
                  </Button>
                  <Button
                    variant="outlined"
                    className="ml-2"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = COMPLAINTS_PAGE_URL;
                    }}
                  >
                    File Another Complaint
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4} sx={{ width: '100%' }}>
            <Card sx={{ width: '100%' }}>
              <CardContent>
                <Typography variant="h3">Contact Info</Typography>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleEmailClick}>
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText primary={<Typography>info@acmainfo.org</Typography>} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <SmartphoneIcon />
                      </ListItemIcon>
                      <ListItemText primary={<Typography>855-255-7137</Typography>} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
